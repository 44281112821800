<template>
  <el-main>
    <page-title show-back-btn />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="企业信息" name="companyInfo">
          <ics-customer-inner :company-detail="companyDetail" :loading-detail="loading" @nextStep="nextStep" />
        </el-tab-pane>
        <el-tab-pane label="企业资料" name="companyData" disabled>
          <ics-company-data-inner :firm-info="firmInfo" :company-data="companyData" :loading-detail="loading" @submit="submitForms" @retreat="activeName = 'companyInfo'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCustomerInner from './components/customer-inner'
import IcsCompanyDataInner from './components/company-data-inner'
export default {
  components: { IcsCustomerInner, IcsCompanyDataInner },
  mixins: [routeEnterMixin],
  data () {
    return {
      firmId: this.$route.query.firmId,
      firmType: this.$route.query.firmType,
      activeName: 'companyInfo',
      companyDetail: {},
      firmInfo: {},
      companyData: {
        tableList: []
      }
    }
  },
  created () {
    if (this.firmId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.admittance.getInfoFirmDetails(this.firmId).then(result => {
        const data  = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.regAddr) {
          regAddr = data.regAddr.split(',')
          data.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.regAddrDetail]
        }
        if (data.workAddr) {
          workAddr = data.workAddr.split(',')
          data.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.workAddrDetail]
        }
        console.log(data)
        if (data.firmAccountInfo) {
          if (data.firmAccountInfo.idCardUrlZ) {
            data.idCardUrlZ = data.firmAccountInfo.idCardUrlZ
          }
          if (data.firmAccountInfo.idCardUrlF) {
            data.idCardUrlF = data.firmAccountInfo.idCardUrlF
          }
        }
        this.firmInfo = {firmId: data.id, firmName: data.companyName}
        this.companyDetail = data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    handleClick () {
      if (this.activeName === 'companyData') {
        this.getCompanyDataList()
      }
    },
    nextStep (formData) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        const data = formData || {}
        let regAddr = ''
        let regAddrDetail = ''
        let workAddr = ''
        let workAddrDetail = ''
        if (data.usEnterpriseAddress !== undefined && data.usEnterpriseAddress.length > 0) {
          const regAddr1 = [data.usEnterpriseAddress[0], data.usEnterpriseAddress[1], data.usEnterpriseAddress[2]]
          regAddr = regAddr1.join(',')
          regAddrDetail = data.usEnterpriseAddress[3]
        }
        if (data.dsEnterpriseAddress !== undefined && data.dsEnterpriseAddress.length > 0) {
          const workAddr1 = [data.dsEnterpriseAddress[0], data.dsEnterpriseAddress[1], data.dsEnterpriseAddress[2]]
          workAddr = workAddr1.join(',')
          workAddrDetail = data.dsEnterpriseAddress[3]
        }
        const info = {
          id: data.id,
          companyShortName: data.companyShortName,
          contactPhone: data.contactPhone,
          email: data.email,
          establishDate: data.establishDate,
          idCardType: data.documentType,
          idCardUrlF: data.idCardUrlF,
          idCardUrlZ: data.idCardUrlZ,
          legalIdentity: data.legalIdentity,
          legalPerson: data.legalPerson,
          licenseUrl: data.licenseUrl,
          regAddr: regAddr,
          regAddrDetail: regAddrDetail,
          regAmount: data.regAmount,
          tel: data.tel,
          workAddr: workAddr,
          workAddrDetail: workAddrDetail,
          licenseType: data.licenseType,
          licenseOperatingPeriod: data.licenseOperatingPeriod,
          idCardValidPeriod: data.idCardValidPeriod
        }
        this.api.admittance.saveBasicsFirm(info).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('保存成功')
            this.getCompanyDataList()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    },
    getCompanyDataList () {
      this.activeName = 'companyData'
      this.loading.list = true
      this.api.admittance.firmFileList(this.firmId, this.firmType).then(result => {
        this.companyData.tableList = result.data.data || []
      }).finally(() => {
        this.loading.list = false
      })
    },
    submitForms () {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        this.api.admittance.commitFirmInfo(this.firmId).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
